import { Language } from "../LangSelector/types";
import { FooterLinkType } from "./types";
import { TwitterIcon, TelegramIcon, RedditIcon, InstagramIcon, GithubIcon, DiscordIcon, MediumIcon } from "../Svg";

export const footerLinks: FooterLinkType[] = [
  {
    label: "About",
    items: [
      {
        label: "MapleSales",
        href: "https://www.maplesales.org/",
      },
      {
        label: "MapleNFT",
        href: "https://nft.maplesales.org/",
      },
      // {
      //   label: "Community",
      //   href: "https://docs.pancakeswap.finance/contact-us/telegram",
      // },
      // {
      //   label: "CAKE",
      //   href: "https://docs.pancakeswap.finance/tokenomics/cake",
      // },
      // {
      //   label: "—",
      // },
      // {
      //   label: "Online Store",
      //   href: "https://pancakeswap.creator-spring.com/",
      //   isHighlighted: true,
      // },
    ],
  },
  {
    label: "TelegramX",
    items: [
      {
        label: "TX Explorer",
        href: "https://www.txpocket.link/",
      },
      {
        label: "TX Bridge",
        href: "https://sd.txpocket.xyz/",
      },
      {
        label: "TX SWAP V1",
        href: "https://v1.txpocket.link/",
      },
    ],
  },
];

export const socials = [
  {
    label: "Twitter",
    icon: TwitterIcon,
    href: "https://twitter.com/PInetwork_erc20",
  },
  {
    label: "Telegram",
    icon: TelegramIcon,
    href: "https://t.me/PI_erc20",
  },
];

// export const socials = [
//   {
//     label: "Twitter",
//     icon: TwitterIcon,
//     href: "https://twitter.com/maplesale_cn",
//   },
//   {
//     label: "Telegram",
//     icon: TelegramIcon,
//     href: "https://t.me/maplesalee",
//   },
//   {
//     label: "Reddit",
//     icon: RedditIcon,
//     href: "https://reddit.com/r/pancakeswap",
//   },
//   {
//     label: "Instagram",
//     icon: InstagramIcon,
//     href: "https://instagram.com/pancakeswap_official",
//   },
//   {
//     label: "Github",
//     icon: GithubIcon,
//     href: "https://github.com/pancakeswap/",
//   },
//   {
//     label: "Discord",
//     icon: DiscordIcon,
//     href: "https://discord.gg/pancakeswap",
//   },
//   {
//     label: "Medium",
//     icon: MediumIcon,
//     href: "https://medium.com/pancakeswap",
//   },
// ];

export const langs: Language[] = [...Array(20)].map((_, i) => ({
  code: `en${i}`,
  language: `English${i}`,
  locale: `Locale${i}`,
}));
